
<template>
  <div>
      <p>
        To get all the benefits of PayPredict after your trial ends please set up your payment details below.
      </p>
      <p  class="mb-5">
        PayPredict is $24.99 per month with unlimited users and unlimited customers monitored. See everything that you get as part of your subscription
        <a
          href="https://evenly.com.au/paypredict/pricing?utm_source=settings&utm_medium=webapp&utm_campaign=scribe"
          target="_blank"
        >HERE</a>.
      </p>

      <!-- v-if="!payment_details" -->
      <button @click="setup_payment" class="btn btn-primary btn-block mb-5">
        Setup Payment Details
      </button>

      <h5>
        Have a Promo Code?
      </h5>
      <p>
        Enter your code below then click on "Apply Code" to have it applied.
      </p>
      <p v-if="promo_api_message">{{ promo_api_message }}</p>
      <input type="text" v-model="promo_code" name="promo_code" class="form-control form-control-lg">
      <button @click="activate_promotion" class="btn btn-primary w-100 mt-4 mb-4">
        Apply Code
      </button>
</div>
</template>


<script>
import api from "@/api.js";

export default {
  name: "subscription-settings",
  data() {
    return {
      promo_api_message: null,
      promo_code: "",
      original_price: "$15.00",
    };
  },
  methods: {
    async setup_payment() {
      var payment_session = (await api.get("crisko-users/me/@@payment-session?orgId=" + this.$store.getters.current_xero_organisation))
        .data;
      var stripe = Stripe(payment_session.stripe_public_key);
      stripe.redirectToCheckout({ sessionId: payment_session.session_id });
    },
    async remove_biller() {
      await api.post('integrations/authed-xero/' + this.$store.getters.current_xero_organisation + '/@@remove-biller');
      await this.$store.dispatch("refresh_session");
    },
    async set_biller() {
      await api.post('integrations/authed-xero/' + this.$store.getters.current_xero_organisation + '/@@set-biller');
      await this.$store.dispatch("refresh_session");
    },
    async delete_payment_details() {
      await api.post('crisko-users/me/@@delete-payment-details');
      await this.$store.dispatch("refresh_session");
    },
    activate_promotion() {
      // Activate promo
      // If success refresh session to update UI and show success message
      // Else show error
      this.promo_api_message = "";
      api.post(
        'integrations/authed-xero/' + this.$store.getters.current_xero_organisation + '/@@activate-promotion',
        {'promo_code': this.promo_code}
      ).then((response) => {
        this.promo_api_message = response.data.message;
        if (response.data.status == "success"){
          this.$store.dispatch("refresh_session");
        }
      })
      .catch(error => {
        console.error(error, 'activation promo code')
        this.promo_api_message = "Failed to apply promo.";
      })
    },
  },
  computed: {
    payment_plan() {
      return this.$store.getters.current_xero_organisation_data.payment_plan;
    },
    payment_due() {
      return this.$store.getters.current_xero_organisation_data.payment_due;
    },
    payment_details() {
      return this.$store.state.session_info.crisko_user_info.payment_details;
    },
    access() {
      return this.$store.getters.current_xero_organisation_data.access;
    },
    is_current_user_biller() {
      return this.$store.getters.current_xero_organisation_data.biller_uuid == this.$store.state.session_info.crisko_user_info.user_uuid;
    },
    biller_name() {
      return this.$store.getters.current_xero_organisation_data.biller_name;
    },
    biller() {
      return this.$store.getters.current_xero_organisation_data.biller_uuid;
    },
    user_is_biller_for_a_different_org() {
      for (const org_data of this.$store.getters.connected_orgs) {
        if (org_data.id != this.$store.getters.current_xero_organisation && this.$store.state.session_info.crisko_user_info.user_uuid == org_data.biller){
          return true
        }
      }
      return false
    },
    org_name() {
      return this.$store.getters.current_xero_organisation_data.name;
    },
    current_price() {
      const amount = this.$store.getters.current_xero_organisation_data.subscription_cost;
      return "$" + (amount / 100).toFixed(2);
    },
    pricing_summary() {
      return this.$store.getters.current_xero_organisation_data.pricing_summary;
    },
    xero_manage_subscription_url() {
      const shortCode = this.$store.getters.current_xero_organisation_data.xero_organisation_short_code;
      const appId = "4039468b-3924-4d24-bbd7-40f74a8930e0";
      return `https://apps.xero.com/${shortCode}/subscribe/${appId}`
    },
    trialEndDate() {
      return this.$store.state.trial_end_date
    },
  }
};
</script>


<style lang="scss">
.upper {
  text-transform: uppercase;
}
</style>
