<template>
  <div>
    <email-sign-up
      v-if="sign_up"
      v-on:exit="sign_up=false"
    />
    <email-login
      v-else-if="login"
      v-on:exit="login=false"
      v-on:switch_to_signup="switch_to_signup"
    />
    <div v-else-if="create_account"
         v-on:exit="create_account=false"  class="d-grid gap-2">
      <h2>Create a PayPredict account for free</h2>
      <p class="mb-4"> Already have an account? <a href="#" @click="create_account=false">Login</a></p>
      <MembershipType />
      <hr>

      <button
          class="btn btn-primary w-100"
          @click="storeIDGoToLogin()"
          id="btnConnectWithXeroAdvisor"
        >
          <img src="@/assets/img/xero-logo.png" style="width: 40px" />
          <span class="ml-3">
              Connect with Xero
          </span>
        </button>
        <button
          class="btn btn-secondary"
          @click="GoogleLogin()"
          style="height: 50px;"
          id="btnSignUpWithGoogle"
        >
          <img id="circle-white-background" src="@/assets/img/google-login.png" />
          <span class="ml-3">
            Sign up with Google
          </span>
        </button>
        <button
          class="btn btn-secondary"
          @click="sign_up=true"
          style="height: 50px;"
          id="btnSignUpWithEmail"
        >
          <span class="ml-3">
            Sign up with email
          </span>
        </button>
    </div>
    <div v-else  class="d-grid gap-2">
      <h2>Login to your free PayPredict account</h2>
      <p class="mb-4">Need an account? <a href="#" @click="create_account=true">Sign up</a></p>
      <MembershipType />
      <hr>
      <button
        class="btn btn-primary"
        @click="storeIDGoToLogin()"
        id="btnConnectWithXeroBusiness"
      >
        <img src="https://evenly.com.au/storage/app/media/app/xero-logo.png" style="width:40px" />
        <span class="ml-3">
          Login with Xero
        </span>
      </button>
      <button
        class="btn btn-secondary"
        @click="GoogleLogin()"
        style="height: 50px;"
        id="btnLoginWithGoogle"
      >
        <img id="circle-white-background" src="https://evenly.com.au/storage/app/media/app/google-login.png" />
        <span class="ml-3">
          Login with Google
        </span>
      </button>
       <button
        class="btn btn-secondary"
        @click="login=true"
        style="height: 50px;"
        id="btnLoginWithEmail"
      >
        <span class="ml-3">
          Login with Email
        </span>
      </button>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MembershipType from "@/components/MembershipType.vue";
import EmailSignUp from "@/components/EmailSignUp.vue";
import EmailLogin from "@/components/EmailLogin.vue";

// Check that email is correct
var emailRegExp = /^[^\][{}|#?/:<>%`\\\x00-\x1f\x7f ][^\][{}|#?/:<>%`\\\x00-\x1f\x7f]*@[^\]"'@[{}|#?/:<>%`\\\x00-\x1f\x7f ]+\.[^\]"'@[{}|#?/:<>%`\\\\\x00-\x1f\x7f ]{2,}$/;

export default {
  name: "login",
  components: {
    MembershipType,
    EmailSignUp,
    EmailLogin,
  },
  data() {
    return {
      sign_up: true,
      login: false,
      create_account: false,
      show_tab: "accountant",
      name: {
        value: "",
        valid: false,
      },
      email: {
        value: "",
        valid: true,
      },
      business: {
        value: "",
        valid: false,
      },
      msg: null,
      msg_class: null,
      submitted: false,
    };
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  },
  methods: {
    show(value) {
      event.preventDefault;
      this.show_tab = value;
    },
    storeIDGoToLogin() {
      // if (
      //   localStorage.membership_type &&
      //   localStorage.membership_id.length > 3
      // ) {
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'click',
          app_action_category: 'oauth',
          app_action_label: 'xero',
      })
      location.assign("/login/xero?intent=supplypredict");

      // }
    },
    GoogleLogin() {
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'click',
          app_action_category: 'oauth',
          app_action_label: 'google',
      })
      location.assign("/login/google?intent=supplypredict");
    },
    resetForm() {
      this.submitted = false;
    },
    validate: function (type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function (value) {
      return emailRegExp.test(value);
    },
    switch_to_signup() {
      this.login = false;
      this.sign_up = true;
    },
  },
  watch: {
    // watching nested property
    "email.value": function (value) {
      this.validate("email", value);
    },
  },
};
</script>

<style lang="scss">
.tabs {
  span {
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-bottom: 2px solid #fff;
    border-radius: 6px 6px 0 0;
    padding: 5px 10px;
    margin: 5px 5px -2px 5px;
    position: relative;
    background: #eee;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &.on,
    &:hover {
      background: #fff;
      padding: 7px 10px 5px;
    }
  }
}
.login-box {
  display: block;
  padding: 20px;
  border: 1px solid #eee;
  background: #fefefe;
  width: 100%;
  border-radius: 6px;
}
@import url("https://cdn-images.mailchimp.com/embedcode/classic-10_7.css");
//Mailchimp
#mc_embed_signup .mc-field-group {
  width: 100%;
}
#mc-embedded-subscribe {
  margin: 0 0 0 0;
}
#circle-white-background {
  background: white;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
</style>
