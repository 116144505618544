<template>
  <div>
    <h4>Reset Password:</h4>
    <p>
      <label>New password:</label>
      <input v-model="new_password" type="password" class="form-control">
    </p>
    <p>
      <label>Repeat password:</label>
      <input v-model="new_password_2" type="password" class="form-control">
    </p>
    <p v-if="password_invalid_reason">{{password_invalid_reason}}</p>
    <p v-if="message">{{message}}</p>
    <button
      class="btn btn-primary btn-block"
      :disabled="!is_password_valid"
      @click="reset"
    >
      <strong>Update Password</strong>
    </button>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  name: "passwordReset",
  data() {
    return {
      new_password: "",
      new_password_2: "",
      message: "",
    };
  },
  computed: {
    password_invalid_reason() {
      if (this.new_password.length < 12) {
        return "Password must be at least 12 characters"
      } else if (this.new_password_2 == "") {
        return "Retype your password"
      } else if (this.new_password != this.new_password_2) {
        return "Passwords must match"
      }
      return null
    },
    is_password_valid() {
      return this.password_invalid_reason == null
    },
  },
  methods: {
    reset () {
      const urlParams = new URLSearchParams(window.location.search);
      api.post(
        '@@update-password',
        {
          password_reset_token: urlParams.get("password_reset_token"),
          email: urlParams.get("email"),
          new_password: this.new_password,
        },
      )
      .then(resp => {
        // Log in using new password
        api.post(
          '@@login',
          null,
          {headers: {
            'Authorization': "Basic " + btoa(urlParams.get("email") + ":" + this.new_password)
          }}
        ).then(resp => document.location = "/")
      })
      .catch(error => {
        if (error.response.data.message) {
          this.message = error.response.data.message
        } else {
          this.message = "Password reset failed. Please refresh and try again."
        }
      })
    },
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  },
};
</script>
