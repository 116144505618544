<template>
  <div>
    <div class="app-main">
      <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <div class="navbar-brand brand-logo mr-5">
            <img src="@/assets/images/paypredict-logo-white.svg" class="mr-2" alt="logo" />
          </div>
        </div>

        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        </div>
      </nav>

      <div class="container-fluid page-body-wrapper">
        <div class="main-panel">
          <div class="content-wrapper">
            <PageTitle title="Update your ABN Details" />

            <div class="row justify-content-md-center" v-if="!showThankyouMessage">
              <div class="col-12 d-flex align-self-stretch">
                <div class="card w-100">
                  <div class="card-body">
                    <h3>
                      <span>{{ $store.state.authed_xero.organisation_name }}</span> Customer details update
                    </h3>
                    <h3>{{ customer_title }}</h3>

                    <div class="row pt-2">
                      <p>Thanks for taking the time to help us update our records.</p>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-10 col-lg-6">
                        <p>In what capacity did you purchase products or services from us?</p>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" id="inlineCheckbox1" name="customer_type" value="business" v-model="customer_type">
                          <label class="form-check-label" for="inlineCheckbox1">As a business with an ABN</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" id="inlineCheckbox2" name="customer_type" value="individual" v-model="customer_type">
                          <label class="form-check-label" for="inlineCheckbox2">As an individual without ABN</label>
                        </div>
                      </div>
                    </div>

                      <!-- business -->
                      <template v-if="customer_type === 'business'">
                        <!-- customer type -->
                        <div class="row mt-2">
                         <div class="col-sm-12 col-md-10 col-lg-6">
                            <p>Simply add your ABN and we'll pull your details from the Australian Business Register</p>

                            <div class="my-3 row">
                              <!-- <label for="abn" class="col-sm-1 col-form-label">ABN</label> -->
                              <div class="col-sm-8 col-md-8">
                                <input class="form-control" id="abn" v-model="search.abn" placeholder="ABN">
                              </div>
                              <div class="col-sm-3 col-md-4">
                                <button type="button" class="btn btn-primary mb-3 w-100" @click="searchByAbn"
                                :disabled="checkingABN">
                                  Enter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                         <div class="col-sm-12 col-md-10 col-lg-6">
                            <h6 class="text-black">
                              Don't know your ABN off the top of your head?
                            </h6>
                            <p>
                              No problem, use the search tool below
                            </p>

                            <div class="my-3 row">
                              <div class="col-sm-8">
                                <input class="form-control" placeholder="SEARCH YOUR BUSINESS NAME" v-model="search.name">
                              </div>
                              <div class="col-sm-4">
                                <button type="button" class="btn btn-primary mb-3 w-100" @click="searchByName"
                                :disabled="checkingABN">
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2" v-if="results.length">
                          <div class="col-sm-12 col-md-10 col-lg-6">
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                  <input type="text" class="form-control" v-model="searchQuery" placeholder="Filter Results"/>
                                </div>
                                <table class="table table-sm table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Business</th>
                                      <th scope="col">ABN</th>
                                      <th scope="col">Status</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(row, index) in resultsFromQuery" :key="index">
                                      <td>
                                        <strong>
                                          {{ index + 1 }}.
                                        </strong>
                                        {{ row.business_name[0] }}
                                      </td>
                                      <td>{{ row.id }}</td>
                                      <td>{{ row.abn_status }}</td>
                                      <td>
                                        <button class="btn btn-primary btn-xs" @click="selectResult(row)">Select</button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <h5>
                                  {{resultsFromQuery.length}} possible matches found.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="!checkingABN && noResult" class="row mt-2">
                          <h5>No Results Found. Please check the ABN/Business.</h5>
                        </div>

                        <div v-if="errorOccured && noResult" class="row mt-2">
                          <h5 class="text-danger">Something went wrong on the search <i class="ti-face-sad"></i>. Please try again later.</h5>
                        </div>

                        <div v-if="checkingABN" class="row justify-content-center">
                          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>

                        <div v-if="!checkingABN && Object.keys(form).length" class="row mt-2">
                          <div class="col-sm-12 col-md-10 col-lg-6">
                            <p>These are the details we have for you. If they're correct, just click Save.</p>
                            <p>if they're incorrect,  please update then click Save.</p>
                          </div>

                          <form @submit.prevent.stop="save_abn()" class="validate-form" novalidate>

                            <div class="my-3 row">
                              <label for="name" class="col-sm-3 col-form-label">Business Name:</label>
                              <div class="col-sm-8">
                                <input
                                  class="form-control"
                                  v-model="form.name"
                                  name="business_name"
                                  data-vv-as="Bussiness Name"
                                  v-validate="'required'">
                                <span class="invalid-feedback" v-if="errors.has('business_name')">
                                  {{ errors.first('business_name') }}
                                </span>
                              </div>
                            </div>
                            <div class="my-3 row">
                              <label for="abn" class="col-sm-3 col-form-label">ABN</label>
                              <div class="col-sm-8">
                                <input class="form-control"
                                  v-model="form.abn"
                                  name="abn"
                                  data-vv-as="ABN"
                                  v-validate="'required'">
                                <span class="invalid-feedback" v-if="errors.has('abn')">
                                  {{ errors.first('abn') }}
                                </span>
                              </div>
                            </div>
                            <div class="my-3 row">
                              <label for="contact_email" class="col-sm-3 col-form-label">CONTACT EMAIL</label>
                              <div class="col-sm-8">
                                <input type="email"
                                  class="form-control"
                                  id="contact_email"
                                  v-model="form.contact_email"
                                  name="email"
                                  data-vv-as="Contact Email"
                                  v-validate="'required|email'">
                                <span class="invalid-feedback" v-if="errors.has('email')">
                                  {{ errors.first('email') }}
                                </span>
                              </div>
                            </div>

                            <!-- save button -->
                            <div class="row mt-2" v-if="showSaveButton">
                              <div class="col-sm-8">
                                <button
                                  :disabled="errors.any()"
                                  type="submit"
                                  class="btn btn-primary "
                                >Save</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </template>

                      <!-- individual -->
                      <template v-if="customer_type === 'individual'">
                        <form @submit.prevent.stop="save_abn()" class="validate-form" novalidate>
                          <div class="row mt-4">
                          <div class="col-sm-12 col-md-10 col-lg-6">
                              <p>These are the details we have for you. If they're correct, just click Save.</p>
                              <p>if they're incorrect,  please update then click Save.</p>


                              <div class="my-3 row">
                                <label for="name" class="col-sm-3 col-form-label">NAME:</label>
                                <div class="col-sm-8">
                                  <input
                                    class="form-control"
                                    v-model="form.name"
                                    name="business_name"
                                    data-vv-as="Name"
                                    v-validate="'required'">
                                  <span class="invalid-feedback" v-if="errors.has('business_name')">
                                    {{ errors.first('business_name') }}
                                  </span>
                                </div>
                              </div>
                              <div class="my-3 row">
                                <label for="contact_email" class="col-sm-3 col-form-label">CONTACT EMAIL</label>
                                <div class="col-sm-8">
                                  <input type="text"
                                    class="form-control"
                                    id="contact_email"
                                    v-model="form.contact_email"
                                    name="email"
                                    data-vv-as="Contact Email"
                                    v-validate="'required|email'">
                                  <span class="invalid-feedback" v-if="errors.has('email')">
                                    {{ errors.first('email') }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- save button -->
                          <div class="row mt-2" v-if="showSaveButton">
                            <div class="col-sm-8">
                              <button
                                :disabled="errors.any()"
                                type="submit"
                                class="btn btn-primary "
                              >Save</button>
                            </div>
                          </div>
                        </form>
                      </template>
                  </div>
                </div>

              </div>
            </div>

            <div class="row justify-content-md-center mt-2" v-if="showThankyouMessage" >
              <div class="col-sm-12 col-md-6 d-flex align-self-stretch">
                <div class="card w-100">
                  <div class="card-body">
                    <div class="row text-center thank-you-message">
                      <h5 class="pb-2">Thanks for taking the time to help us with our records.</h5>
                      <h6>
                        Powered by <a href="https://evenly.com.au/paypredict?utm_source=app&utm_medium=app&utm_campaign=post_abn_ud" target="__blank">Evenly PayPredict</a>
                      </h6>
                      <h6 class="mt-1">
                        Get to know your customers better and improve your cash flow
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import api from "@/api.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: 'customerupdateabn',
  components: {
    PageTitle,
  },
  data() {
    return {
      checkingABN: false,
      showThankyouMessage: false,
      isSearchBusinessName: false,
      noResult: false,
      customer_type: null,
      form: {},
      results: [],
      searchQuery: null,
      search: {},
      orgId: this.$route.params.orgId,
      profile_id: this.$route.params.profileId,
      errorOccured: false,
      customer_title: null,
      orgInfo: {}
    }
  },
  created() {
    this.getOrg()
  },
  watch: {
    customer_type(val) {
      if (val === 'individual') {
        this.form = {
          name: this.orgInfo.contact_person,
          contact_email: this.orgInfo.contact_email,
        }
      } else {
        this.form = {
          contact_email: this.orgInfo.contact_email,
        }
      }
    }
  },
  computed: {
    ...mapState([
      'is_fetching_contacts_report'
    ]),
    ...mapGetters([ "current_xero_organisation"]),

    report_item() {
      return this.$store.state.contacts_report.items.find((item) => {
        return item.profile_id === this.profile_id;
      }) || {};
    },

    self_data() {
      return this.has_report_item ? this.report_item.profile.self_data : null;
    },
  },
  methods: {

    getOrg() {
      this.loading = true;

      api.get('integrations/authed-xero/' + this.orgId + '/reports/current/contacts/@@search')
      .then(({ data }) => {
        this.orgInfo = data.data.items.find(({ identity }) => (identity == this.$route.params.profileId))
        this.customer_title = this.orgInfo.title
      })
    },

    save_abn() {
      if (!this.showSaveButton) {
        return
      }

      this.$validator.validate().then(valid => {
        if (valid) {
          api.post(
            '@@update-xero-customer-abn',
            {

              ...this.form,
              customer_type: this.customer_type
            },
            {headers: {'Xero-customer-identity': this.token}},
          ).then(() => {
            this.showThankyouMessage = true;
          })
        }
      })
    },
    searchByAbn() {
      this.form = {}
      this.isSearchBusinessName = false
      this.checkAbn()
    },
    searchByName() {
      this.form = {}
      this.isSearchBusinessName = true
      this.checkAbn()
    },
    async checkAbn() {
      try {
        this.results = [];
        this.form = {};
        this.noResult = false;
        this.checkingABN = true;

        // GET request using fetch with async/await
        const qry = this.isSearchBusinessName ? this.search.name : this.search.abn
        const apiKey = '$2y$10$qklUBAD8bHi6i.uHMPSus.cqr841lrPtF8sVeEnr.3vQMP8cB2sl2'
        const url = `https://abn-lookup.com.au/api/search?qry=${qry}&state=ALL&key=${apiKey}`
        const response = await fetch(url);
        const data = await response.json();
        if (data.length > 1) {
          this.results = data
        } else if (data.length === 1) {
          const { business_name, id } = data[0];

          this.form = {
            name: business_name[0],
            abn: id,
            contact_email: this.orgInfo.contact_email
          }
        }

        if (data.length == 0) {
          this.noResult = true
        }
        this.errorOccured = false
      } catch (error) {
        this.noResult = true
        this.errorOccured = true
        this.form = {}
      } finally {
        setTimeout(() => {
          this.checkingABN = false
        }, 500);
      }
    },
    selectResult({ id, business_name }) {
      this.form = {
        name: business_name[0],
        abn: id,
        contact_email: this.orgInfo.contact_email
      }

      this.$toast.open({ message: "ABN selected - please scroll down to confirm and save"})
    },
  },
  computed: {
    resultsFromQuery() {
      if(this.searchQuery){
        return this.results.filter((item)=>{
          return this.searchQuery.toLowerCase().split(' ').every(v => item.business_name[0].toLowerCase().includes(v))
        })
      }else{
        return this.results;
      }
    },
    isButtonEnabled() {
      if (this.customer_type === 'business') {
        return this.form.abn && this.form.name && this.form.contact_email
      }

      return this.form.name && this.form.contact_email
    },
    showSaveButton() {
      if (this.customer_type === 'business') {
        return !this.noResult && Object.keys(this.form).length
      }

      if (this.customer_type === 'individual') {
        return true
      }

      return false
    },
    supplier() {
      return new URL(window.location.href).searchParams.get("org_name")
    },
    token() {
      return new URL(window.location.href).searchParams.get("token")
    },
    customer() {
      return new URL(window.location.href).searchParams.get("contact_name")
    },
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  }
}
</script>

<style scoped>
tr {
  width: 100%;
  display: inline-table;
  height:60px;
  table-layout: fixed;
}

table {
 height:300px;
 display: -moz-groupbox;
}

tbody{
  overflow-y: scroll;
  height: 200px;
  width: 95%;
  position: absolute;
}
.thank-you-message h5,
.thank-you-message h6 {
  color: #000000;
}
</style>
