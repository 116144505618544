<template>
  <div class="floating-alert loaded">{{ this.popup_message }}</div>
</template>
<script>
export default {
  name: "PopUpMessage",
  props: {
    popup_message: String
  }
};
</script>

<style scoped lang="scss">
.floating-alert {
  display: block;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 80px;
  padding-top: 25px;
  color: #fff;
  text-align: center;
  z-index: 9000;
  //animation: 1s ease-out 0s 1 slideInFromTop;

  background: linear-gradient(270deg, #4361c2, #4fa49a, #9dc66b);
  background-size: 600% 600%;

  -webkit-animation: waitGradient 6s ease infinite;
  -moz-animation: waitGradient 6s ease infinite;
  animation: waitGradient 6s ease infinite;
}
@-webkit-keyframes waitGradient {
  0% {
    background-position: 0% 50%;
    transform: translateY(-100%);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    background-position: 100% 50%;
  }
  40% {
    background-position: 0% 50%;
  }
  60% {
    background-position: 0% 50%;
  }
  80% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes waitGradient {
  0% {
    background-position: 0% 50%;
    transform: translateY(-100%);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    background-position: 100% 50%;
  }
  40% {
    background-position: 0% 50%;
  }
  60% {
    background-position: 0% 50%;
  }
  80% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes waitGradient {
  0% {
    background-position: 0% 50%;
    transform: translateY(-100%);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    background-position: 100% 50%;
  }
  40% {
    background-position: 0% 50%;
  }
  60% {
    background-position: 0% 50%;
  }
  80% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
