<template>
  <AppModal>
    <template v-slot:title>Collecting and analysing your data</template>
    <div class="login">
      <p>This wont take long, we are analysing your account records...</p>
      <p v-if="sync_status === null">
        Generating contacts report...
        <span v-if="!contacts_report_dirty">done.</span>
      </p>
      <p v-else>{{ sync_status }}</p>

      <div class="waitText" :class="{showWaitText : this.showWaitText}">
        <hr class="mt-4" />
        <h4 class="mt-4">Taking a while?</h4>

        <p>You can try clicking on the button below to see if that helps.</p>

        <a class="btn btn-primary btn-block mt-4 mb-4" href="/" id="btnLoadYourDashboard">
          <strong>Load Your Dashboard</strong>
        </a>

        <p>Otherwise, if you have a large amount of contacts or invoices it could take 10-20 minutes for Xero to share your data with us.</p>
        <p>
          No need to wait around if that’s the case.
          <strong>We’ll send you an email when everything is ready</strong> for you and you can finish set up then.
        </p>
        <p>Shouldn’t take long, so keep an eye out for it :)</p>
      </div>
    </div>
    <template v-slot:buttons>
      <i class="fas fa-2x fa-spinner fa-pulse"></i>
    </template>
  </AppModal>
</template>

<script>
// @ is an alias to /src

import AppModal from "@/components/AppModal.vue";

export default {
  name: "stand-by",
  data() {
    return {
      showWaitText: false
    };
  },
  components: {
    AppModal
  },
  mounted() {
    this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
    this.displayWaitTimer();
    this.$store.dispatch("reload_authed_xero").then(() => {
      this.$store.dispatch("reload_contacts_report")
    })
  },
  methods: {
    displayWaitTimer() {
      this.showWaitText = false;
      window.heap.track('Loading Xero Content');
      setTimeout(() => (
        this.showWaitText = true,
        window.heap.track('Loading Xero Time-Out')
        ), 20000);
    }
  },
  computed: {
    sync_status() {
      return this.$store.state.authed_xero.sync_status;
    },

    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },

    contacts_report_dirty() {
      return this.$store.state.contacts_report_dirty;
    }
  }
};
</script>

<style lang="scss" scoped>
.waitText {
  display: none;
  &.showWaitText {
    display: block;
    overflow: hidden;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
