<template>
  <div>
    <div class="form-group">
      <label>
        Choose your association membership
        <sup>*</sup>
      </label>
      <select
        name="membership_type"
        :class="{ danger: error.membership_type }"
        v-model="membership.membership_type"
        @change="onChange($event)"
        class="form-control form-control-lg form-control-block"
      >
        <option value="Chartered Accountants">Chartered Accountants - CA</option>
        <option value="Certified Practising Accountant">Certified Practising Accountant - CPA</option>
        <option value="Institute of Public Accountants">Institute of Public Accountants - IPB</option>
        <option value="Tax Practitioners Board">Tax Practitioners Board - TPB</option>
      </select>
      <span v-if="error.membership_type">Association membership type is required</span>
    </div>
    <div class="form-group mt-2">
      <label>
        {{ type_display }}
        <br />
          <small>NB: Don't have your membership number - Don't worry, leave this blank and we'll be in touch later on.</small>
      </label>
      <input
        type="text"
        name="membership_id"
        :class="{ danger: error.membership_ID }"
        v-model="membership.membership_id"
        @blur="onBlur($event)"
        placeholder="Your membership ID"
        class="form-control form-control-lg"
      />
      <span v-if="error.membership_ID">This ID is required</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "membership_type",
  data() {
    return {
      type_display:
        localStorage.membership_type != null
          ? localStorage.membership_type + " ID"
          : "Enter your Member / Registration ID",
      error: {
        membership_ID: false,
        membership_type: false
      },
      membership: {
        membership_type: localStorage.membership_type,
        membership_id: localStorage.membership_id
      }
    };
  },
  methods: {
    onChange(event) {
      this.type_display = event.target.value + " ID";
      localStorage.membership_type = this.membership.membership_type;
      if (localStorage.membership_type == "") {
        this.error.membership_type = true;
      } else {
        this.error.membership_type = false;
      }
    }
    // onBlur() {
    //   localStorage.membership_id = this.membership.membership_id;
    //   if (localStorage.membership_id.length <= 4) {
    //     this.error.membership_ID = true;
    //   } else {
    //     this.error.membership_ID = false;
    //   }
    // }
  }
};
</script>

<style lang="css" scoped>
</style>
