<template>
  <div>
    <p>
      <label>Email:</label>
      <input v-model="email" type="email" class="form-control">
    </p>
    <p class="error_msg" v-if="email_error">{{ email_error }}</p>
    <p>
      <label>Password:</label>
      <input v-model="password" type="password" class="form-control">
    </p>
    <p class="error_msg" v-if="password_error">
      {{ password_error }}
      <a v-if="show_signup_link" @click="switch_to_signup">HERE</a>
    </p>
    <p>
      <div class="row g-0 align-items-end">
        <div class="col">
          <label>Email Verification Code</label>
          <input v-model="otp" name="otp" type="text" class="form-control">
        </div>
        <button
          @click="send_email_otp"
          :loading="otp_sending"
          class="btn btn-sm btn-primary mx-2 py-2 col-4">
          Send Code
        </button>
      </div>
    </p>
    <p v-if="otp_sent" class="alert alert-success">
      <strong>Sent.</strong> Check your inbox.
    </p>
    <p class="error_msg" v-if="otp_error">
      {{ otp_error }}
    </p>
    <button
      class="btn btn-primary btn-block"
      @click="login"
      :disabled="loading"
    >
      <span class="ml-3">
        <strong>{{ buttonText }}</strong>
      </span>
    </button>
    <a href="#" @click="reset">
      <p style="padding-top: 12px;">Forgot Password</p>
    </a>
    <a href="#" @click="exit">
      <p>Use a differnt sign in method</p>
    </a>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  name: "emailLogin",
  data() {
    return {
      loading: false,
      email: "",
      email_error: null,
      password: "",
      password_error: null,
      show_signup_link: false,
      otp: null,
      otp_sending: false,
      otp_error: null,
      otp_sent: false,
    };
  },
  computed: {
    buttonText (){
      if (this.loading) {
        return "Loading..."
      }
      return "Login"
    }
  },
  methods: {
    async send_email_otp() {

      // Reset state if the button was pressed a second time
      this.otp_error = null;
      this.otp_sent = false;

      // Make async request
      this.otp_sending = true;
      let response = null;
      let error = null;
      try {
        const authorization = "Basic " + btoa(this.email + ":" + this.password);
        response = await api.post(
          '@@send-email-otp',
          {},
          { headers: { authorization } }
        );
      } catch(err) {
        error = err;
        if (error.response) {
          response = error.response;
        }
      }
      this.otp_sending = false;

      // Process result
      if (response !== null) {
        if (response.status === 200) {
          this.otp_sent = true;
        } else if (response.status === 403) {
          this.otp_error = "Invalid username or password"
        } else {
          this.otp_error = "Unexpected error";
        }
      } else {
        this.otp_error = error | "Unexpected error"
      }
    },
    login() {
      // Reset error messages
      this.email_error = null
      this.password_error = null
      this.show_signup_link = false

      // Check email and password
      if (this.email == ""){
        this.email_error = "This field is required"
        return
      }
      if (this.password == ""){
        this.password_error = "This field is required"
        return
      }

      // Attempt to login
      this.loading = true
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'login',
          app_action_category: 'login',
          app_action_label: 'password',
      })

      api.post(
        '@@login',
        {otp: this.otp},
        {headers: {
          'Authorization': "Basic " + btoa(this.email + ":" + this.password)
        }}
      )
      .then(resp => document.location = "/")
      .catch(() => this.password_error = "Invalid email/password.")
      .then(() => this.loading = false)
      // Todo deal with login failure
    },
    reset() {
      // Reset error messages
      this.email_error = null
      this.password_error = null
      this.show_signup_link = false

      // Check email
      if (this.email == ""){
        this.email_error = "Please enter your email to reset your password"
        return
      }

      // Attempt to reset password
      this.loading = true
      api.post('@@reset', {email: this.email})
      .then(resp => {
        this.password_error = "Check your inbox to complete password reset."
      })
      .catch(error => {
        this.show_signup_link = true
        this.password_error = "We can't find an account with that email address. Please Sign Up" // link appears after
      })
      .then(() => this.loading = false)
    },
    exit() {
      this.$emit("exit")
    },
    switch_to_signup() {
      this.$emit("switch_to_signup")
    }
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
</style>
