<template>
  <div class="col-md-12 grid-margin">
    <div class="row">
      <div class="col-12 col-xl-12 mb-4 mb-xl-0">
        <h1>
          <span v-html="title" />
          <slot name="append" />
        </h1>
        <p v-html="aside" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-title",
  props: {
    title: String,
    aside: String,
  }
};
</script>
