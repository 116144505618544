export default {
  data() {
    return {
      colors: {
        belowAverage: '#dc3545',
        average: '#fd7e14',
        good: '#ffc107',
        veryGood: '#45b782',
        excellent: '#198754',
        abnMissing: '#555555',
        abnButNoScore: '#bbbbbb',
      }
    }
  },
  methods: {
    getColor(score, hasAbn = true) {
      if (score >= 0 && score <= 509) {
        return this.colors.belowAverage;
      } else if (score >= 510 && score <= 612) {
        return this.colors.average;
      } else if (score >= 613 && score <= 725) {
        return this.colors.good;
      } else if (score >= 726 && score <= 832) {
        return this.colors.veryGood;
      } else if (score >= 833) {
        return this.colors.excellent;
      } else if (!hasAbn) {
        return this.abnMissing
      } else if (hasAbn && !score) {
        return this.abnButNoScore
      }
    },
    getTrend(trend) {
      if (trend === 'stable') {
        return 'Stable'
      } else if (trend === 'desc') {
        return 'Deteriorating'
      } else if (trend === 'asc') {
        return 'Improving'
      } else {
        return 'No Data'
      }
    },
    getCategory(score, prsExcluded = null) {
      if (!score && !prsExcluded) {
        return 'ABN Missing';
      } else if (!score && prsExcluded <= 0) {
        return 'No Score';
      } else if (parseInt(score) < 510) {
        return 'Below Average';
      } else if (parseInt(score) < 622) {
        return 'Average';
      } else if (parseInt(score) < 726) {
        return 'Good';
      } else if (parseInt(score) < 833) {
        return 'Very Good';
      } else if (parseInt(score) > 833) {
        return 'Excellent';
      }
    }
  }
}