<template>
  <div >
    <div class="d-grid gap-2" id="org-selector">
      <h2>How often do you usually reconcile your accounts?</h2>

      <p>
        Sharing your reconciliation habits will help us provide more accurate payment predictions.
      </p>

      <p>
        <span class="text-bold">NB</span>:We know most people would like to reconcile more often but please be sure to select how often you usually reconcile rather than how often you think you should be doing it.
      </p>

      <p>
        If you're not sure, select the closest one for now. You can change it later.
      </p>

      <p>
        <select
          v-model="settings.reconciliation_time_frame"
          @change="updateFrequency()"
          class="form-select"
        >
          <option value="weekly">Weekly</option>
          <option value="fortnightly">Fortnightly</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="annually">Annually</option>
        </select>
      </p>


      <button
        class="btn btn-primary mt-4"
        @click="$emit('done')"
      >
        <span class="ml-3">
          Next
        </span>
      </button>
    </div>

  </div>
</template>

<script>

export default {
  name: "FrequencyReconciliationOnboarding",
  props: {
    v2AccountId: {
      required: true
    },
  },
  data() {
    return {
      settings: {
        reconciliation_time_frame: null
      },
    }
  },
  methods: {
    updateFrequency() {
      api.patch(
        `/v2/accounts/${this.v2AccountId}`,
        {
          reconciliation_time_frame: this.settings.reconciliation_time_frame,
        },
      )
      .then(resp => {
        this.$store.dispatch("get_users_connected_accounts")
      })
      .catch(error => {
        this.$toast.open({
          message: error,
          type: "error"
        })
      })

    },
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  },
};
</script>
<style type="text/css" scoped>

</style>
