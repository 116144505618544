import Vue from 'vue'
import Router from 'vue-router'

/* Implemented as a factory in order to prevent the router from
 * auto loading
 */
function router_factory() {

    Vue.use(Router)

    return new Router({
        routes: [
            {
                path: '/',
                name: 'push',
                component: () => import('./views/RedirectHandler.vue'),
                meta: {
                    title: "Evenly PayPredict"
                }
            },
            {
                path: '/dashboard/',
                name: 'home',
                component: () => import('./views/Dashboard.vue'),
                meta: {
                    title: "Dashboard"
                }
            },
            {
                path: '/org-selector/',
                name: 'orgselector',
                component: () => import('./views/OrgSelector.vue'),
                meta: {
                    title: "Choose Organisation"
                }
            },
            {
                path: '/overdue-invoice-list/',
                name: 'overdueinvoicelist',
                component: () => import('./views/OverdueInvoiceList.vue'),
                meta: {
                    title: "Overdue Invoices"
                }
            },
            {
                path: '/overdue-invoice-list-basic/',
                name: 'overdueinvoicelistbasic',
                component: () => import('./views/CustomerOverdueInvoicesBasic.vue'),
                meta: {
                    title: "Customer Overdue Invoices"
                }
            },
            {
                path: '/overdue-invoice-list-detail/',
                name: 'overdueinvoicelistdetail',
                component: () => import('./views/CustomerOverdueInvoicesDetail.vue'),
                meta: {
                    title: "Customer Overdue Invoices in Detail"
                }
            },
            {
                path: '/customer-list/:customer_risk_type?',
                name: 'customerlist',
                component: () => import('./views/CustomerList.vue'),
                meta: {
                    title: "Your Customers"
                }
            },
            {
                path: '/customer-list-message/:customer_risk_type',
                name: 'customerlistmessage',
                component: () => import('./views/CustomerListMessage.vue'),
                meta: {
                    title: "Send your customers a message."
                }
            },
            // {
            //     path: '/customer-list/',
            //     name: 'customerlist',
            //     component: () => import('./views/CustomerList.vue'),
            //     meta: {
            //         title: "Your Customers"
            //     }
            // },
            {
                path: '/settings/',
                name: 'settings',
                component: () => import('./views/Settings.vue'),
                meta: {
                    title: "Account Settings"
                }
            },
            {
                path: '/invite/',
                name: 'invite',
                component: () => import('./views/Invite.vue'),
                meta: {
                    title: "Share PayPredict with friends"
                }
            },
            {
                path: '/contact/',
                name: 'contact',
                component: () => import('./views/Contact.vue'),
                meta: {
                    title: "Contact Us (coming soon?)"
                }
            },
            {
                path: '/connected-businesses/',
                name: 'connectedbusinesses',
                component: () => import('./views/ConnectedBusinesses.vue'),
                meta: {
                    title: "Your Connected Businesses"
                }
            },
            {
                path: '/profile/:id',
                name: 'profile',
                component: () => import('./views/Profile.vue'),
                meta: {
                    title: "Customer Profile"
                }
            },
            {
                path: '/customer-message/',
                name: 'customermessagethanks',
                component: () => import('./views/CustomerMessageThanks.vue'),
                meta: {
                    title: "Send Customers a Reminder or Thanks"
                }
            },
            {
                path: '/customer-message/:filter/',
                name: 'customermessagethanksfilter',
                component: () => import('./views/CustomerMessageThanks.vue'),
                meta: {
                    title: "Send Customers a Reminder or Thanks"
                }
            },
            {
                path: '/customer-message/:filter/:id',
                name: 'customermessagethanksid',
                component: () => import('./views/CustomerMessageThanks.vue'),
                meta: {
                    title: "Send Customers a Reminder or Thanks"
                }
            },
            {
                path: '/request-email',
                name: 'request_email',
                component: () => import('./views/RequestAccess.vue'),
                meta: {
                    title: "Send Customers a Reminder or Thanks"
                }
            },
            {
                path: '/customer-update-abn/:orgId/:profileId',
                name: 'customer_update_abn',
                component: () => import('./views/CustomerUpdateABN.vue'),
                meta: {
                    title: "Get Customers to update their ABN Details"
                }
            },
            {
                path: '/invoices',
                name: 'invoices',
                component: () => import('./views/InvoicesList.vue'),
                meta: {
                    title: "List of Invoices"
                }
            },
            {
                path: '/alert-list',
                name: 'alertlist',
                component: () => import('./views/AlertList.vue'),
                meta: {
                    title: "Alert List"
                }
            },
            {
                path: '/portfolio-insights',
                name: 'portfolioinsights',
                component: () => import('./views/PortfolioInsights.vue'),
                meta: {
                    title: "Portfolio Insights"
                }
            },
            {
                path: '/quickcheck',
                name: 'quickcheck',
                component: () => import('./views/QuickCheck.vue'),
                meta: {
                    title: "QuickCheck"
                }
            },
        ],
        mode: "history",
        base: "/pp/",
        scrollBehavior() {
            return { x: 0, y: 0 }
        }
    })
}

export default router_factory
