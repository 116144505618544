<template>
  <div >
    <div v-if="xero_is_connected"  class="d-grid gap-2">
      <template v-if="showFrequencyReconForm">
        <FrequencyReconciliationOnboarding :v2-account-id="selectedOrg.v2_account_id" @done="doneOnboarding"/>
      </template>
      <template v-else>
        <h2>Select an Organisation</h2>
        <div v-for="org in connected_orgs" :key="org.id" class="d-grid gap-2">
          <button class="btn btn-secondary" style="height: 50px;" @click="setSelectedOrg(org)">
            {{ org.name }}
            <strong v-if="org.payment_plan == 'minireport'">&mdash; Start Free Trial</strong>
          </button>
        </div>
        <button
          class="btn btn-primary"
          @click="goToXeroLogin()"
          id="btnConnectWithXeroIdle"
        >
          <img
            src="@/assets/img/xero-logo.png"
            style="width: 40px"
          />
          <span class="ml-3">
            Connect Another Organisation
          </span>
        </button>
      </template>
    </div>
    <div v-else  class="d-grid gap-2">
      <h2>Almost there. One last step…</h2>
      <p>The final step is to connect your Xero Accounts, to let us know which customers to report on.</p>
      <p><strong>Select the option that best applies to you:</strong></p>

      <button
        class="btn btn-primary"
        @click="goToXeroLogin()"
        id="btnConnectWithXeroIdle"
      >
        <img
          src="@/assets/img/xero-logo.png"
          style="width: 40px; float:left;"

        />
        <span class="ml-3" style=" line-height:40px;">
          Connect my existing Xero account
        </span>
      </button>

      <button
        class="btn btn-default"
        @click="formRemindMeLater = !formRemindMeLater"
        id="btnXeroReminderLater"
      >
        <img
          src="@/assets/img/xero-logo.png"
          style="width: 40px; float:left;"
        />
        <span class="ml-3" style=" line-height:40px;">
          Remind me via email to connect later
        </span>
      </button>

      <!-- send info for later -->
      <transition name="fade" mode="out-in">
      <div v-show="formRemindMeLater" class="card">
        <div class="card-body">
          <p>
            Email yourself a reminder for later at {{ settings.email.value }}
          </p>
          <div class="alert" :class="settings.msg_class" v-if="settings.msg_reminder">{{settings.msg_reminder}}</div>
          <button
          v-on:click="submitReminderForLater"
          class="btn btn-primary w-100"
          id="btnSendReminderForLater"
        >Send</button>
        </div>
      </div>
      </transition>


      <button
        class="btn btn-default"
        @click="formEmailAccountant = !formEmailAccountant"
        id="btnXeroAccountant"
      >
        <img
          src="@/assets/img/xero-logo.png"
          style="width: 40px; float:left;"
        />
        <span class="ml-3" style=" line-height:40px;">
          My accountant handles Xero
        </span>
      </button>

      <!-- send info to accountant -->
      <transition name="fade" mode="out-in">
      <div v-show="formEmailAccountant" class="card">
        <div class="card-body">

          <p>
            Add your accountant's name and email address below and we’ll send them instructions. You can also copy the text and send them an email yourself.
          </p>
          <div class="alert" :class="settings.msg_class" v-if="settings.msg_accountant">{{settings.msg_accountant}}</div>
          <input
            type="text"
            name="accountant_name"
            v-model="settings.accountant_name"
            placeholder="Accountant's Name"
            required
            class="form-control mb-2"
          />
          <input
            type="email"
            name="email"
            v-model="settings.accountant_email.value"
            :class="settings.accountant_email.input_class"
            placeholder="Accountant's Email"
            required
            class="form-control mb-2"
          />
          <button
          v-on:click="submitAccountant"
          class="btn btn-primary w-100"
          id="btnSendAccountant"
          >Send</button>
          <div class="alert-info alert mt-2" v-html="emailContentHTML">

          </div>
          <div class="justify-content-between">
            <button
              @click="doCopy"
              class="btn btn-primary btn-sm"
              data-bs-toggle="popover"
              data-bs-trigger="focus"
              data-bs-content="Copied"
              :class="btnStyle"
            >
              {{ copyMessage }}
            </button>
          </div>
        </div>
      </div>
      </transition>


      <a
        class="btn btn-default"
        href="https://evenly.com.au/paypredict/other-software"
        target="_blank"
        id="btnDontUseXero"
      >

        <span class="ml-3" style=" line-height:40px;">
          I don't use Xero
        </span>
      </a>
    </div>

    <div v-if="upgradeError" class="alert alert-danger mt-3" role="alert">
        {{ upgradeError }}
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
import FrequencyReconciliationOnboarding from './FrequencyReconciliationOnboarding.vue';
var emailRegExp = /^[^\][{}|#?/:<>%`\\\x00-\x1f\x7f ][^\][{}|#?/:<>%`\\\x00-\x1f\x7f]*@[^\]"'@[{}|#?/:<>%`\\\x00-\x1f\x7f ]+\.[^\]"'@[{}|#?/:<>%`\\\\\x00-\x1f\x7f ]{2,}$/;

export default {
  name: "OrgSelector",
  components: {
    FrequencyReconciliationOnboarding
  },
  data() {
    return {
      upgradeError: null,
      showForm: false,
      formRemindMeLater: false,
      formEmailAccountant: false,
      formNoXero: false,
      copyMessage: "Copy",
      btnStyle: "btn-primary",
      settings: {
        name: this.$store.state.session_info.crisko_user_info.name,
        email: {
          value: this.$store.state.session_info.crisko_user_info.email,
          valid: true,
        },
        accountant_name: "",
        accountant_email: {
          value: "",
          valid: true,
          input_class: null
        },
        msg_reminder: null,
        msg_accountant: null,
        msg_class: null
      },
      emailContentHTML: `<p>Hi<br><br>
          I found this app, PayPredict, to help me better understand how reliable my customers are.<br><br>
          Could you please set this up on my accounts then let me know when I can access the insights?<br><br>
          Click the link below to check out PayPredict and get started<br><br>
          https://evenly.com.au/advisor-refer<br><br>
          Thanks</p>`,
      emailContentText: `Hi
          I found this app, PayPredict, to help me better understand how reliable my customers are.

          Could you please set this up on my accounts then let me know when I can access the insights?

          Click the link below to check out PayPredict and get started.
          https://evenly.com.au/advisor-refer

          Thanks.`,
      showFrequencyReconForm: false,
      selectedOrg: {},
    }
  },
  computed: {
    connected_orgs() {
      return this.$store.getters.connected_orgs
    },
    xero_is_connected() {
      return this.$store.getters.xero_is_connected
    },
  },
  methods: {
    goToXeroLogin() {
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'connect-org',
          app_action_category: 'org-selector',
          app_action_label: 'xero',
      })
      location.assign("/login/xero?intent=paypredict");
    },
    doCopy() {
        this.$copyText(this.emailContentText)
        this.copyMessage = "Copied"
        this.btnStyle = "btn-success"
    },
    submitReminderForLater(){
      window.console.log("Submit later")
      var settings = {
        contact_name: this.settings.name,
        contact_email: this.settings.email.value,
      };
      if (this.settings.email.valid) {
        api
          .post("@@final-step-send-for-later", settings)
          .then(() => {
            this.settings.msg_accountant = null;
            this.$toast.open({ message: "We have sent you a reminder to connect Xero."})
            this.submitted = true;
          })
          .catch((error) => {
            this.error = error;
            this.$toast.open({
                message: "Sorry, " + this.error,
                type: "error"
            })
          });
      }
    },
    submitAccountant(){
      var settings = {
        contact_name: this.settings.name,
        contact_email: this.settings.email.value,
        accountant_name: this.settings.accountant_name,
        accountant_email: this.settings.accountant_email.value
      };
      if (this.settings.accountant_email.valid) {
        window.console.log("Submit Accountant")
        this.settings.accountant_email.input_class = "is-valid"
        api
          .post("@@final-step-send-login-to-accountant", settings)
          .then(() => {
            this.settings.msg_reminder = null;
            this.$toast.open({ message: "We have sent an email to your accountant on your behalf."})
            this.submitted = true;
          })
          .catch((error) => {
            this.error = error;
            this.$toast.open({
                message: "Sorry, " + this.error,
                type: "error"
            })
          });
      } else {
        window.console.log("NO Submit Accountant")
        this.settings.accountant_email.input_class = "is-invalid"
      }
    },
    validate: function (type, value) {
      if (type === "email") {
        //this.settings.email.valid = this.isEmail(value) ? true : false;
        this.settings.accountant_email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function (value) {
      return emailRegExp.test(value);
    },
    setSelectedOrg(org) {
      this.selectedOrg = org
      if (org.payment_plan == 'minireport') {
        this.showFrequencyReconForm = true
      } else {
        this.showFrequencyReconForm = false
        const url = new URL(window.location.href)

        this.$cookie.set("link_action", "dashboard","1h")
        url.searchParams.set('orgId', org.id)
        // Note: setting window.location.href refreshes the page
        window.location.href = url.toString()
      }
    },
    async doneOnboarding() {
      this.upgradeError = null

      if (this.selectedOrg.payment_plan == 'minireport') {
        try {
          await api.post('integrations/authed-xero/' + this.selectedOrg.id + '/@@paypredict-upgrade')
        } catch (err) {
          this.upgradeError = err
          return
        }
      }

      const url = new URL(window.location.href)
      this.$cookie.set("link_action", "dashboard","1h")
      url.searchParams.set('orgId', this.selectedOrg.id)
      // Note: setting window.location.href refreshes the page
      window.location.href = url.toString()
    }
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  },
  watch: {
    // watching nested property
    "settings.accountant_email.value": function (value) {
      this.validate("email", value);
    },
  },
};
</script>
<style type="text/css" scoped>

</style>
